@import 'src/themes/theme-custom';


html, body{
  margin: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eef1f5;
}

#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.k-button-solid-primary, .k-button-solid-tertiary{
  color: #ffffff;
}

.k-input-values {
  display: flex;
  .k-chip-list {
    display: flex;
    gap: 8px;
  }
  .k-input-inner {
    padding: 8px 0;
  }
  .k-chip-actions {
    cursor: pointer;
  }
}

.k-grid th.text-right {
  text-align: right;
}
th.text-right .k-cell-inner > .k-link {
  justify-content: right;
}
