.attributeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;

  &_fieldset{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #FAFAFA;
    padding: 6px 12px;
    border-radius: 8px;
  }

  &_actions{
    display: flex;
    width: 64px;
    justify-content: flex-end;
  }

  &_restrictions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: all ease-in-out .3s;

    span{
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }

    &_controls{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px
    }

    &_control{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      gap: 8px
    }
  }
}

.active{
  max-height: 150px;
  margin: 8px 0;
}

.bold{
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 500;
}
