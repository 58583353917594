.stickyTableHeader{
  position: fixed;
  top: 64px;
  border: 1px solid #EAEBF0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 2;
  overflow: hidden;

  &_content{
    display: block;
    white-space: nowrap;
    div{
      display: inline-block;
      font-weight: 700 !important;
      background: white;
      white-space: nowrap;
      padding: 6px 12px;
      font-size: 13px;
      border-right: 1px solid #EAEBF0;
    }
  }
}

.app-table {
  table {
    border-collapse: collapse;

    &.fixed {
      //table-layout: fixed;
      //width: 200px;
    }

    &.sticky{

      th{
        font-weight: 700 !important;
        background: white;
      }
    }

    td, th {
      font-size: 14px;
      padding: 0 16px 0 4px;
      border: 1px solid #e2e2e2;
      //width: 180px;
      //word-wrap: break-word;
    }

    th{
      white-space: nowrap;
    }

    &.main {
      width: 100%;

      td, th {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        padding: 8px 12px;
      }

      thead {
        th {
          font-weight: 700;
        }
      }
    }

    &.table-header,
    &.table-footer {
      td, th {
        font-size: 12px;
        padding: 4px 6px;
      }
    }
  }

  .collapsable {
    display: table;
    background: #F8F9FA;
    border: 1px solid #EAEBF0;
    border-radius: 3px;
    padding: 12px;
    .title {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #495058;
      padding-bottom: 12px;
      transition: padding-bottom 0.1s ease;
    }

    .content {
      transition: height 0.1s ease;
      overflow: hidden;
      background: #ffffff;
      .table-wrapper{
        border: 1px solid #EAEBF0;
        border-radius: 3px;
        overflow: hidden;

        table{
          margin: -1px
        }
      }
    }

    &.collapsed{
      .title{
        padding: 0;
      }
    }
  }
}
