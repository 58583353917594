.action-prompt-dialog {
  .MuiDialogTitle-root{
    font-size: 18px;
    border-bottom: 1px solid #DEE2E6;
    padding: 16px;
  }
  .MuiDialogActions-root{
    border-top: 1px solid #DEE2E6;
    padding: 14px;
  }
  .MuiDialogContent-root{
    max-width: 412px;
    padding: 18px 16px;
  }

  button{
    text-transform: none;
  }

  ul{
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    list-style: none;
    padding: 0;

    li {
      white-space: pre-wrap;
      padding-bottom: 14px;
      a{
        color: inherit;
        &:hover{
          color: var(--color-primary);
        }
      }
    }
  }

}
