.node-type{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 35px 70px auto;
  gap: 10px 10px;
  grid-template-areas:
    "breadcrumbs"
    "header"
    "grid";
  height: 100%;
  width: 100%;

  &__breadcrumbs {
    grid-area: breadcrumbs;
  }

  &__header {
    grid-area: header;
  }

  &__grid {
    grid-area: grid;
    overflow: hidden;
    table {
      width: 100% !important;
    }
    .grid-wrapper{
      overflow: hidden;
    }
    .k-grid-header-menu.k-grid-column-menu {
      margin: 0;
      text-align: right;
    }
    .k-card {
     height: 100%;
    }
  }



}
