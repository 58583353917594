.component-item-header{
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    .title{
        padding: 0 10px;
        white-space: nowrap;
        font-size: 18px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .spacer{
        flex: 1;
    }
}

