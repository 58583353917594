.configurator-sidebar-card{
  width: 100%;
  height: 100%;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .k-card-title{
    padding: 22px 20px 23px 15px;
    border-bottom: 1px solid #E9ECEF;
    height: 65px;

    &.collapsed {
      height: 44px;
      padding: 4px;
      margin: 0;
    }
  }

  .k-card-body{
    padding: 16px 16px;

    &.collapsed {
      padding: 0;
    }

    .item{
      display: flex;
      flex-direction: column;
      padding: 16px 0 0;

      a {
        cursor: pointer;
      }

      &.collapsed {
        padding: 4px 4px 0 4px;
        border-bottom: 1px solid #E9ECEF;

        &:last-of-type {
          border-bottom: none;
        }

        a {
          width: 36px;
          height: 36px;
          border-radius: 5px;
          margin-bottom: 4px;
        }
      }

      p{
        color: var(--text-secondary);
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }


  .k-card-actions{
    display: flex;
    gap: 14px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 27px 0;
    border-top: 1px solid #E9ECEF;
    color: var(--text-secondary);
    a{
      text-decoration: none;
      color: inherit;
    }

    &.collapsed {
      border-top: none;
      padding: 4px;
    }
  }

  .list-button {
    width: 100%;
    padding: 10px 11px;
    .k-button-text{
      color: inherit;
      display: flex;
      width: 100%;
      align-items: flex-start;
      gap: 12px;
      text-transform: none;
      line-height: 16px;
    }
  }
}
