.cabinet {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 180px auto;
  transition: grid-template-columns 0.4s ease;
  gap: 20px 32px;
  grid-template-areas:
    "sidebar target";
  padding:32px;
  height: calc(100% - 64px);

  &.collapsed {
    grid-template-columns: 45px auto;
  }

  &__sidebar {
    position: relative;
    grid-area: sidebar;
    max-height: calc(100vh - 64px);
    transition: width 0.4s ease;
    width: 190px;
    position: fixed;
    height: 100%;

    &.collapsed {
      width: 45px;
    }

    .sideBarArrow {
      display: flex;
      position: absolute;
      width: 18px;
      height: 100%;
      top: 0;
      right: -11px;

      img {
        position: relative;
        opacity: 0;
        width: 18px;
        height: 18px;
        top: 50%;
        cursor: pointer;
        transition: opacity ease-out 200ms;
      }

      &:hover {
        img {
          opacity: 1;
          transition: opacity ease-in 200ms;
        }
      }
    }
  }
  &__target {
    grid-area: target;
    max-width: calc(100vw - 276px);

    &.collapsed {
      max-width: calc(100vw - 141px);
    }
  }
}
