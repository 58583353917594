@import "~@progress/kendo-theme-material/scss/expansion-panel/index.scss";

.k-expander-title {
  color:inherit;
  font-size: 18px;
  text-transform: none;
}

.k-expander-indicator{
  color: #2196F3;
  .k-icon{
    font-size: 22px;
  }
}

.k-expander-indicator:after{
  content: 'развернуть';
  font-size: 14px;
}

.k-expanded .k-expander-indicator:after{
  content: '';
}
