.dictionary-type {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-template-rows: 35px 70px auto;
  gap: 10px 10px;
  grid-template-areas:
    "breadcrumbs"
    "header"
    "grid";
  height: 100%;
  width: 100%;

  &__breadcrumbs {
    grid-area: breadcrumbs;
  }

  &__header {
    grid-area: header;
  }

  &__grid {
    grid-area: grid;
    overflow: hidden;
    table {
      width: 100% !important;

      th{
        min-width: 150px;
      }
    }
    .grid-wrapper{
      overflow: auto;
    }
  }
    .k-grid-header-menu.k-grid-column-menu {
      margin: 0;
      text-align: right;
    }
}

.name_cell {
  width: 100%;
  min-width: 250px;
  display: table;
  table-layout: fixed;
  position: relative;

  &> div{
      display: table-cell;
  }

  .k-icon {
      width: 24px;
  }

  .name_cell__wrap{
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      padding-right: 30px;
      max-width: fit-content;

      span{
          display: inline;
          min-width: 240px;
      }
  }
  .name_cell__icon{
      text-align: left;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;

      .info{
          width: 16px;
          height: 16px;
          display: inline-flex;
          margin: 0 10px;
          color: #1976D2;
          cursor: pointer;
          vertical-align: text-top;
          svg{
              width: 100%;
              height: 100%;
          }
      }
  }

  &:hover {
    .name_cell__icon{
      opacity: 1;
    }
  }
}

.info_popover {
  width: 350px;

  .info_popover__wrapper {
      padding: 8px 0;
  }

  .info_popover__title{
      padding: 16px;
      font-weight: 500;
      background: #F8F9FA;
      border-bottom: 1px solid #DEE2E6;
  }

  table {
      border-collapse: collapse;
      width: 100%;

      td {
          border-bottom: 1px solid #DEE2E6;
          padding: 8px 16px;
          vertical-align: top;
          white-space: pre-wrap;
      }

      tr:last-child > td {
          border-bottom: none;
      }
  }
}
