.spec-breadcrumbs {
  margin-bottom: 10px;
}


.specification-item {
  display: flex;
  flex-direction: column;
  width: 100%;


  .header,
  .tabs,
  .target,
  .info {
    margin-bottom: 10px;
  }

  .target {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    .forms {
      flex-grow: 1;
    }

    .info {
      position: sticky;
      width: 340px;
      min-width: 340px;;
      display: flex;
      top: 10px;
      flex-direction: column;
      gap: 10px;

      //padding-right: 28px;
      & > div {
        background: #FFFFFF;
      }

      &__status {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        width: 100%;

        .title {
          color: var(--text-secondary-tint);
          padding: 14px;

          &.calculated {
            background: var(--color-green);
          }

          &.error {
            background: var(--color-error);
          }

          &.empty {
            background: var(--color-gray);
          }

          &.inProgress {
            background: var(--color-gray);
          }

        }

        &__helper {
          padding: 12px 14px;
          font-weight: 500;
          font-size: 11px;
          line-height: 140%;

          ul {
            padding: 0;
            margin: 0;

            li {
              list-style: none;
            }
          }

          &.empty {
            color: var(--text-secondary)
          }

          &.error {
            color: var(--color-error)
          }
        }

        &__recalculate {
          padding: 12px 14px;
          overflow: hidden;

          button {
            width: 100%;
            border-radius: 3px;
          }

          .MuiLoadingButton-loadingIndicator{
            position: relative;
            padding: 0 16px;
          }
        }
      }
    }
  }

  .doc {
    margin-bottom: 32px;
  }

  .target {
    .splitter {
      transition: height 300ms ease;
      //padding: 16px 0;
    }
  }

  .k-card-body {
    height: 100%;
    padding: 24px;
  }

  .form-wrapper {
    padding-top: 16px;

    .MuiAccordion-root:last-of-type {
      margin-bottom: 16px;
    }
  }

  .empty-result {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #6C757D;
    text-align: center;
  }

  .item-nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 !important;
    background: var(--background-plate-nested);

    .nav-tabs {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tab,
      a,
      a:visited {
        padding: 13px 24px;
        border-bottom: 2px solid #E9ECEF;
        cursor: pointer;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-decoration: none;
        color: var(--tab-text);

        &.active {
          color: #00BEC8;
          border-bottom: 2px solid #00BEC8;
        }

        &.disabled {
          opacity: .5;
          color: #495057;
          cursor: default;
        }
      }
    }
  }

  .tabs {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: -12px -24px 0;
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.9);

    &.sticky {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }

    .item-tabs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;


      .form-tabs {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;
        padding: 4px;
        border: 1px solid var(--border-color);
        border-radius: 3px;

        .more-menu {
          padding: 5px;
          margin: -9px 0 -5px 0;
          color: inherit;
        }
      }

      .tab {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px;
        gap: 4px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        border-bottom: none;

        .check {
          color: #EAEBF0;

          &.filled {
            color: #00BEC8;
          }
        }


        &.active {
          background: #00BEC8;
          color: var(--text-secondary-tint);
          pointer-events: none;

          .filled {
            color: inherit;
          }

          .check {
            opacity: .4;

            &.filled {
              color: #FFFFFF;
              opacity: 1;
            }
          }
        }
      }

      .switch {
        border-bottom: 2px solid transparent;
        padding-right: 16px;

        .k-label {
          cursor: pointer;
        }

        .k-switch {
          margin-left: 8px;
        }
      }
    }
  }


}

.progress {
  position: relative;
  .percent {
    position: absolute;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    opacity: 0;
    overflow: hidden;
    transition: opacity .3s ease-in-out 2s;
  }

  .label {
    display: inline-flex;
    opacity: 1;
    transition: all .3s ease-in-out 2.5s;

  }

  &.active {
    .percent {
      opacity: 1;
      transition: opacity .2s ease-in-out .2s;
    }

    .label {
      opacity: 0;
      overflow: hidden;
      transition: opacity .2s ease-in-out;
    }
  }

}

.cancel {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 0;
  padding-top: 0;
  overflow: hidden;

  &.active{
    padding-top: 8px;
    height: 25px;
  }
}
