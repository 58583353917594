.configurator-header-card{
  width: 100%;

  button, a{
    text-transform: none;
  }

  .wrapper{
    padding: 18px;

    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;

      &.title-warning {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10px;
      }

      h3{
        font-size: 18px;
        font-weight: 400;
        margin: 0;
      }
    }

    .search {
      padding: 15px 16px;
      background: #F8F9FA;
      border: 1px solid #E9ECEF;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-field{
        width: 50%;
        max-width: 390px;
      }
    }

    &.inline{
      padding: 16px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      .title{
        margin-bottom: 0;
      }
      .search{
        padding: 0;
        flex-grow: 1;
        border: none;
        background-color: transparent;
        justify-content: flex-start;
        .search-field{
          margin-right: 10px;
        }
      }
    }


  }

  .warning-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    min-height: 40px;
    margin-bottom: 10px 0;

    span {
      width: 6px;
      background-color: #CD9200;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    div.warning-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 100%;
    }

    div.warning-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 6px 0;
      background-color: #FFF3D4;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #CD9200;
        margin: 0;
      }
    }
  }

  &__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

