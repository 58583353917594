.breadcrumbs-card{
  padding: 10px 16px;
  &.d-flex{
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
  }
}

.breadcrumbs{
  display: flex;
  gap: 12px;
  align-items: center;

  a, .item{
    display: flex;
    text-decoration: none;
    color: #6C757D;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    span{
      display: flex;
      align-items: center;
      gap: 6px;
    }
    &:last-child {
      overflow: hidden;
      text-overflow:  ellipsis;
      display: inline;
    }
  }
  & > div {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
