.specification-price {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  &__row {
    display: flex;
    flex-display: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    border-bottom: 1px solid var(--color-divider);

    &__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: var(--text-secondary);
    }

    &__data {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.price {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      .currency-input {
        position: relative;
        background: var(--background-plate-nested);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        padding: 8px 8px 8px 16px;

        .icon {
          position: absolute;
          left: 8px;
        }

        input {
          border: none;
          min-width: 30px;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          background: inherit;
          outline: none;
        }
      }

      .current-currency {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #C1C6CC;
        align-items: flex-end;
        padding-right: 8px;
        
        div:last-of-type {
          position: relative;
          display: flex;
          background: rgba(33, 150, 243, 0.08);
          border-radius: 3px;
          padding: 2px 4px;
          font-weight: 500;
          color: var(--color-primary);
          pointer-events: none;
        }
        
        &.enabled{
          div:last-of-type {
            pointer-events: auto;
            cursor: pointer;
            overflow: visible;
            &:hover{
              text-decoration: underline;
            }
            &:hover:after {
              content: '';
              position: absolute;
              width: 0;
              height: 0;
              top: 50%;
              right: -5px;
              margin-top: -3.5px;
              border-style: solid;
              border-width: 3.5px 0 3.5px 5px;
              border-color: transparent transparent transparent rgba(33, 150, 243, 0.08);
              z-index: 2;
            }
            &.disabled{
              pointer-events: none;
              cursor: default;
            }
          }

        }
        
      }
    }
  }

  &__icon {
    color: #6C757D;
  }

  &__rate {
    margin: 0 8px;

    input {
      border: 1px solid #D9D9D9;
      width: 60px;

      &:focus,
      &:focus-visible {
        border: 1px solid #495057;
        outline: none;
      }
    }
  }

  span {
    padding: 0 8px;
  }
}
