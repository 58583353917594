.adminTypeItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;

  &__breadcrumbs {
    width: 100%;
  }

  &__form {
    max-width: 620px;
  }

  &__header {
    width: 100%;
    background: var(--background-plate-nested);

    &_content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 66px;
      padding: 16px;
      border-bottom: 1px solid var(--color-divider);
      font-size: 18px;
      font-weight: 500;

      &_item {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      &_version {
        display: flex;
        padding: 6px 8px;
        font-size: 11px;
        line-height: 15px;
        background: var(--color-bright-gray);
        border-radius: 4px;

      }
    }
  }

  &__content {
    flex-grow: 1;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    padding: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--background-color);

    &_json {
      height: 100%;
      margin: 0 -26px;
      animation: fadeIn, .5s;
      -webkit-animation: fadeIn .5s;
      -moz-animation: fadeIn .5s;
      -o-animation: fadeIn .5s;
      -ms-animation: fadeIn .5s;

    }

    &_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
      color: var(--text-secondary);
    }

    &_editor {
      flex-grow: 1;
    }

    &_form {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      gap: 24px;

      &_fields{
        flex-grow: 1;
      }

      &_attribute_header{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;

      }

      &_actions{
        display: flex;
        flex-direction: column;
        margin-top: -44px;
        width: 330px;
        gap: 8px;
      }

      &_attributes_wrapper{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

    }

  }


}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
